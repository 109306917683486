/*
=====================
CSS Custom Font
===================== 
*/

@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa-Light.woff2") format("woff2"),
    url("../fonts/Nexa-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nexa";
  src: url("../fonts/NexaRegular.woff2") format("woff2"),
    url("../fonts/NexaRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa-Bold.woff2") format("woff2"),
    url("../fonts/Nexa-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select option {
  background: #0d0d0d;
  color: #fff;
}

body {
  font-family: "Nexa";
  font-weight: 400;
}

.svg-white-color {
  filter: brightness(0) invert(1);
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/*
===================== 
CSS Custom Font
===================== 
*/
/* Styles for dialog window */
.madal-dialog {
  max-width: 448px;
  width: 100%;
  margin: auto;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--Dark-4, #1a1a1a);
  background: var(--Dark-4, #1a1a1a);
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

/**
 * Fade-zoom animation for first dialog
 */
.mfp-close-btn-in .mfp-close {
  color: #ffffff;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
=====================
CSS Custom Font
===================== 
*/
.header-toggler.active {
  border-color: #00c2ff;
}
@media only screen and (max-width: 768px) {
  .header-navbar {
    overflow: hidden;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: 300ms ease all;
  }

  .header-navbar.active {
    height: max-content;
    display: block;
    top: 80px;
    background: #1e1d1d;
    padding: 24px;
  }
}

/*
=====================
CSS Custom Font
===================== 
*/
#timer-clocker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

#timer-clocker .timer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 92px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #4d4d4d;
  color: #00c2ff;
  font-size: 48px;
  font-weight: 700;
}

#timer-clocker .dots {
  color: #a6a6a6;
  font-size: 72px;
  font-weight: 700;
}

/*
=====================
CSS Custom Font
===================== 
*/
.vanilla-calendar {
  width: 100% !important;
  background: transparent !important;
  padding: 0 !important;
}

.vanilla-calendar-week__day,
.vanilla-calendar-months__month,
.vanilla-calendar-day__btn {
  background: transparent !important;
  color: #cccccc !important;
  font-size: 16px;
  padding: 18px 0;
}
.vanilla-calendar-years__year,
.vanilla-calendar-day__btn_selected,
.vanilla-calendar-day__btn_selected:hover,
.vanilla-calendar-day__btn_today {
  background-color: #00c2ff !important;
  color: #ffffff !important;
}
.vanilla-calendar-year {
  position: relative;
}
.vanilla-calendar-year::before {
  content: url(../images/calendar-arrow.svg);
  position: absolute;
  right: -19px;
  top: 3px;
}
.vanilla-calendar-header__content {
  justify-content: left !important;
  order: 0 !important;
  font-size: 20px !important;
}

.vanilla-calendar-header__content,
.vanilla-calendar-year,
.vanilla-calendar-header__content,
.vanilla-calendar-month,
.vanilla-calendar-header__content {
  color: #ffffff !important;
}

.vanilla-calendar-arrow {
  position: absolute !important;
  right: 0 !important;
}

.vanilla-calendar-arrow_prev {
  right: 30px !important;
}

.vanilla-calendar-arrow:before {
  mask-image: none !important;
  transform: none !important;
}

.vanilla-calendar-arrow:hover:before {
  background-color: transparent !important;
}

.vanilla-calendar-arrow_prev:before {
  background-image: url(../images/calendar-top.svg) !important;
  background-repeat: no-repeat;
}

.vanilla-calendar-arrow_next:before {
  background-image: url(../images/calendar-bottom.svg) !important;
  background-repeat: no-repeat;
}

/*
=====================
CSS Custom Font
===================== 
*/
.form-checkbox {
  position: relative;
  cursor: pointer;
}

.form-checkbox span {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}

.form-checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
}

.form-checkbox input[type="checkbox"]::before {
  content: "";
  width: 130px;
  height: 42px;
  display: block;
  z-index: 2;
  position: relative;
  top: 0;
  border-radius: 26px;
  border: 1px solid #4d4d4d;
}

.form-checkbox input[type="checkbox"]::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 26px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.form-checkbox input[type="checkbox"]:checked::after {
  background: #00c2ff;
}

/*
=====================
CSS Custom Font
===================== 
*/
.form-widget.active-btn1,
.form-widget.active-btn2,
.form-widget.active-btn3,
.form-widget.active-btn4,
.form-widget.active-btn5 {
  display: block !important;
}
.dashboard-disable {
  border: 1px solid #4d4d4d;
  background: rgba(7, 7, 7, 0.5);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
}

.dashboard-disable.comming {
  border: 1px dashed #4d4d4d;
  background: var(--Dark-3, #141414);
}

.wallet-adapter-button {
  border: 1px solid #4d4d4d !important;
  background-color: #070707 !important;
  border-radius: 9999px !important; /* Using a large value for roundness to create a circular shape */
  color: #757575 !important;
  cursor: pointer !important;
  display: inline-flex !important;
  justify-content: flex-start !important;
  padding: 1rem !important;
  transition: border-color 0.3s, color 0.3s !important;
  /* width: 100% !important; */
}

.wallet-adapter-button:hover {
  border-color: #00c2ff !important;
  color: #00c2ff !important;
}
