@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@100..900&display=swap");

.cursor-pointer {
  cursor: pointer;
}

.inactive {
  opacity: 0.5;
  transition: opacity 0.6s ease-in-out;
}

.active {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.invisible {
  opacity: 0;
}

.project-title-pill {
  background-color: #00c2ff;
  padding: 8px 16px;
  border-radius: 30px;
  margin-bottom: 24px;
  width: fit-content;
  color: #070707;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nexa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.back-page-title {
  font-weight: 700;
  font-size: 46px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.back-page-title h3 {
  margin: 0%;
}

.title-arrow {
  height: 50px;
}

/* table {
  display: block;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
thead {
  width: calc( 100% - 1em )
} */

tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  gap: 16px !important;
}

.lb-tbody {
  padding-right: 12px;
  margin-right: -8px !important;
}
.lb-tbody::-webkit-scrollbar {
  width: 3px;
}

.lb-tbody::-webkit-scrollbar-thumb {
  background-color: #5993a5d3;
}

.lb-tbody::-webkit-scrollbar-track {
  background-color: grey;
}

.about-us-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(99vh - 70px);
  background: url(./assets/images//aboutus-bg.png);
  background-size: 100% 100%;
}

.page-title {
  color: var(--Light, #fcfcfc);
  font-feature-settings: "clig" off, "liga" off;

  /* Bold/H4 */
  font-family: Nexa;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 94.444% */
  margin-bottom: 24px;
}

.how-it-works-title {
  color: rgba(255, 255, 255, 0.85);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 70.833% */
  letter-spacing: 0.2px;
  margin-bottom: 30px;
  /* margin-top: -56px; */
}
.how-it-works {
  background: #070707;
}

.how-it-works-card {
  border: 1px solid #062639 !important;
  background: url(./assets/images//how-it-works-card-bg.png);
  background-size: 100% 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 194, 255, 0.1);
  font-family: Inter;
}

.text-gray-0 {
  color: #fcfcfc !important;
}

.coming-soon {
  padding: 0% 0px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12.993px;
  font-style: normal;
  font-weight: 800;
  line-height: 12.453px; /* 95.843% */
  letter-spacing: 3.113px;
  margin-top: 16px;
}

.coming-soon p {
  border: 0.519px solid rgba(0, 194, 255, 0.15);
  background: rgba(0, 194, 255, 0.1);
  box-shadow: 0px 0px 9.711px 0px rgba(0, 194, 255, 0.15);
  display: flex;
  height: 33.209px;
  padding: 5.189px 26.463px;
  justify-content: center;
  align-items: center;
  gap: 5.189px;
  width: fit-content;
  margin: auto;
}

.work-bottom li {
  letter-spacing: 0.2px;
  line-height: 20px;
}

.get-started-btn {
  max-width: 90%;
  width: 360px;
  height: 54px;
  justify-content: center;
  color: #070707;
  font-weight: 700;
  font-size: 20px;
  /* margin-top: -15px; */
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px; /* 120% */
  letter-spacing: 1px;
}
/* .how-it-works-steps-wraper {
  overflow: auto;
  padding: 20px 0px 0px;
} */

.how-it-works-steps-wraper::-webkit-scrollbar {
  width: 0px;
}

.how-it-works-steps {
  display: flex;
  justify-content: center;
  height: 55px;
  align-items: center;
  border-radius: 14px;
  border: 1px solid #4d4d4d;
  width: 100%;
  margin-bottom: 44px;
  border-left: 2px solid #00c2ff;
  border-right: 2px solid #00c2ff;
  /* min-width: 1166px; */
}

.how-it-works-steps .step {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 0px 20px;
}
.how-it-works-steps .step-divider {
  width: 79px;
  height: 73px;
  border: 1px solid #4d4d4d;
  border-radius: 14px;
  align-items: center;
  background-color: #070707;
}

.how-it-works-steps-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 55px; */
  align-items: center;
  border-radius: 14px;
  border: 1px solid #4d4d4d;
  width: fit-content;
  margin-bottom: 44px;
  border-top: 2px solid #00c2ff;
  border-bottom: 2px solid #00c2ff;
  /* min-width: 1166px; */
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0px;
  display: none;
  width: 165px;
  text-align: center;
  font-size: 19px;
  box-shadow: #0d3b4b8a 0px 0px 80px 0px;
}

.how-it-works-steps-mobile .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}
.how-it-works-steps-mobile .step-divider {
  /* width: 152px;
  height: 142px; */
  border: 1px solid #4d4d4d;
  border-radius: 12px;
  align-items: center;
  background-color: #070707;
  margin: 20px 0px;
  transform: rotate(90deg);
  width: 64px;
  height: 60px;
}

.text-gray-800 {
  color: #4d4d4d;
}

.easy-text {
  color: rgba(255, 255, 255, 0.85);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  margin: -6px 0px 44px;
}

.work-card-number {
  color: var(--Blue, #00c2ff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 64px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 37.5% */
  letter-spacing: 0.2px;
  margin-bottom: 44px;
}

.work-card-title {
  color: rgba(255, 255, 255, 0.85);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 3px;
  width: fit-content;
}

.work-card-title + img {
  margin-bottom: 16px;
}

.shadow-neon {
  box-shadow: 0px 0px 50px 0px rgba(0, 194, 255, 0.25);
}

.dashboard-area {
  color: #ffffffd9 !important;
}

.task-bg {
  background-image: url(./assets/images//task-card.svg);
  background-size: 100% 100% !important;
}

.db-tag {
  background-image: url(./assets/images//dashboard-tag.svg);
  background-size: 100% 100% !important;
}

.db-card {
  background-image: url(./assets/images//db-card.png);
  background-size: 100% 100% !important;
}

.db-main-card {
  background-image: url(./assets/images//dasboard-main-card.svg);
  background-size: 100% 100% !important;
}

/* .db-card-wedge {
  background-image: url(./assets/images//db-card-wedge.svg);
  background-size: 100% 100% !important;
} */

.connect-wallet-btn {
  background-image: url(./assets/images//connect-wallet-btn.svg);
  background-size: 100% 100% !important;
}

.wallet-adapter-modal-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.wallet-adapter-collapse {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tgme_widget_login.medium button.tgme_widget_login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1.472px solid #00c2ff; */
  background-color: #00c2ff;
  color: black !important;
  font-size: 22px !important;
  font-weight: medium;
  line-height: 1.25; /* Equivalent to leading-8 */
}

@media only screen and (max-width: 1024px) {
  .db-tasks-card {
    background-image: url(./assets/images//db-main-card-mobile.svg);
    background-size: 100% 100% !important;
  }
  .db-main-card {
    background-image: none;
  }
}

@media only screen and (max-width: 867px) {
  .how-it-works-steps-mobile {
    display: flex;
  }
  .how-it-works-steps {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .how-it-works-title {
    font-size: 34px;
    font-weight: 900;
    line-height: 38px;
    margin-top: -10px;
  }

  .easy-text {
    font-size: 28px;
    font-weight: 700;
  }
  .header-navbar {
    background: url(./assets/images//mobile-nav-bg.png) !important;
    background-size: 100% 100% !important;
    font-family: Inter;
  }
  .header-navbar a {
    font-weight: 600;
    font-size: 16px;
    /* color: #FCFCFC; */
  }
  .header-navbar .signin-btn {
    width: 90vw;
    justify-content: center;
    color: #00c2ff;
    background: rgba(0, 194, 255, 0.1);
    font-weight: 700;
    height: 58px;
  }
}
@media only screen and (max-width: 600px) {
  .db-card {
    background-image: url(./assets/images//db-card-mobile.png);
  }
  .back-page-title {
    font-size: 30px;
  }
  .title-arrow {
    height: 30px;
  }
  .hero-grid {
    max-height: 340px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
  }
  .hero-scroll {
    position: absolute;
    z-index: 3;
    bottom: 0%;
    height: 2px;
    width: 10px; /* Initial width set to 0, as the progress bar starts empty */
    background-color: aliceblue;
    transition: width 0.2s ease; /* Adding a smooth transition effect */
  }
  .hero-grid-item {
    min-width: 100vw;
  }
  .hero-grid::-webkit-scrollbar {
    width: 10px;
  }
  .home-footer {
    display: none;
  }
  .how-it-works-title {
    font-size: 24px;
    line-height: normal;
    margin-top: -10px;
  }

  .work-card-number {
    font-size: 56px !important;
    font-weight: 700;
    line-height: 21.638px;
    letter-spacing: 0.18px;
  }
  .about-us-flex {
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;
  }
  .work-card-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 21.638px;
    letter-spacing: 0.18px;
  }
  .easy-text {
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .title-underline {
    width: 140px;
  }
  .how-it-works-card {
    font-size: 14px !important;
  }
  .get-started-btn {
    width: 235px;
    height: 46px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 640px) {
  .wallet-adapter-dropdown {
    width: 100%;
  }
  .wallet-adapter-dropdown button {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .hero-area {
    transform: scale(0.9) !important;
  }

  @media only screen and (max-height: 885px) {
    .hero-area {
      transform: scale(0.8) !important;
    }
  }

  @media only screen and (max-height: 800px) {
    .hero-area {
      transform: scale(0.7) !important;
    }
  }

  @media only screen and (max-height: 720px) {
    .hero-area {
      transform: scale(0.6) !important;
    }
  }

  @media only screen and (max-height: 640px) {
    .hero-area {
      transform: scale(0.55) !important;
    }
  }
}

@media only screen and (min-width: 1280px) {
  @media only screen and (max-height: 885px) {
    .hero-area {
      transform: scale(0.9) !important;
    }
  }

  @media only screen and (max-height: 800px) {
    .hero-area {
      transform: scale(0.8) !important;
    }
  }

  @media only screen and (max-height: 720px) {
    .hero-area {
      transform: scale(0.7) !important;
    }
  }

  @media only screen and (max-height: 640px) {
    .hero-area {
      transform: scale(0.64) !important;
    }
  }
}
